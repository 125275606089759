<template>
  <div class="container">
    <div class="static-page">
      <div class="processing">
        <img src="@/assets/images/static-page/processing/processing.png" :style="style">
        <label>Страница находится в стадии разработки</label>
        <router-link to="/" class="btn btn-info">Перейти на главную</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      turn: 0.1,
      style: '',
    }
  },
  mounted() {
    this.rotation();
  },
  methods: {
    rotation: function () {
      setTimeout(() => {
        this.turn += 0.1;
        this.style = "transform: rotate(" + this.turn + "turn)";
        this.rotation();
      }, 130);
    },
  }
}
</script>